/*eslint strict:0 */
(function(root){

var titleFilters = (function() {
    'use strict';

    var localeMap, changeTitleCase;

    localeMap = {
        'capitalize': ['en-gb'],
        'allWords': ['en-us']
    };

    changeTitleCase = {

        "capitalize": function(phrase) {
            return phrase.charAt(0).toUpperCase() + phrase.slice(1);
        },

        "allWords": function(phrase) {
            var phrases = phrase.split(' '),
                phrasesLength = phrases.length;

            while (phrasesLength--) {
                phrases[phrasesLength] = this.capitalize(phrases[phrasesLength]);
            }

            return phrases.join(' ');
        }
    };

    return function(phrase) {
        var locales,
            index,
            name,
            fn;

        // retrieve correct fn for title case
        for (name in localeMap) {
            locales = localeMap[name];
            index = locales.length;
            while (index--) {
                if (locales[index] === this.locale) {
                    fn = name;
                }
            }
        }

        return changeTitleCase[fn || 'allWords'](phrase);
    };

})();

var Filter = (function() {
    'use strict';

    var FILTERS = {
        "title": titleFilters
    };

    function FilterController(controller) {
        this.controller = controller;
        return this.initialize();
    }

    var prototypes = {

        "empty": function() {
            this.queue = [];
            return this;
        },

        "initialize": function() {
            this.filters = {};
            return this.empty().set(FILTERS);
        },

        "invoke": function(phrase, path, phrases) {
            var listLength = this.queue.length,
                context,
                phrasesTarget,
                filter;

            phrase = phrase || '';
            this.queue.reverse();

            if (!listLength) {
                return phrase;
            }

            // helper context to pass to individual filters
            context = {
                "locale": this.controller.get('locale')
            };

            while (listLength--) {

                if (phrases && (phrasesTarget = phrases[path + '_filter_' + this.queue[listLength]])) {
                    phrase = phrasesTarget;
                    continue;
                }

                filter = this.filters[this.queue[listLength]];

                // trigger each filter with helper context and updated phrase argument
                if (filter instanceof Function) {
                    phrase = filter.call(context, phrase) || phrase;
                }
            }

            this.empty();
            return phrase;
        },

        "set": function(filter, fn) {
            var filters = {};

            // create one or many filters
            if (filter instanceof Object) {
                filters = filter;
            } else {
                filters[filter] = fn;
            }

            for (filter in filters) {
                this.filters[filter] = filters[filter];
            }

            return this;
        },

        "enqueue": function(filters) {
            if (!(filters instanceof Array)) {
                filters = [filters];
            }
            // update list of filetrs to trigegr
            this.queue = this.queue.concat(filters);
            return this;
        }
    };

    for (var method in prototypes) {
        FilterController.prototype[method] = prototypes[method];
    }

    return FilterController;

})();

var REGEXP = (function() {
    'use strict';

    return {
        "attribute": /\[(.*?)\]/g,
        "argument": /\((.*?)\)/g,
        "escape": /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
        "trim": /^\s+|\s+$/g,
        "whitespace": /\s/g
    };

})();

/*eslint no-nested-ternary:1 */
var Pluralization = (function() {

    'use strict';

    var pluralizationRules, localeMapping;

    pluralizationRules = {
        "chinese": function(n) { return 0; },
        "german": function(n) { return n !== 1 ? 1 : 0; },
        "french": function(n) { return n > 1 ? 1 : 0; },
        "russian": function(n) { return n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2; },
        "czech": function(n) { return n === 1 ? 0 : (n >= 2 && n <= 4) ? 1 : 2; },
        "polish": function(n) { return n === 1 ? 0 : n % 10 >= 2 && n % 10 <= 4 && ((n % 100 < 10 || n % 100 >= 20) ? 1 : 2); },
        "icelandic": function(n) { return (n % 10 !== 1 || n % 100 === 11) ? 1 : 0; }
    };

    localeMapping = {
        "chinese": ['fa', 'id', 'ja', 'ko', 'lo', 'ms', 'th', 'tr', 'zh'],
        "german": ['da', 'de', 'en-us', 'en-gb', 'es', 'fi', 'el', 'he', 'hu', 'it', 'nl', 'no', 'pt', 'sv'],
        "french": ['fr', 'tl', 'pt-br'],
        "russian": ['hr', 'ru'],
        "czech": ['cs'],
        "polish": ['pl'],
        "icelandic": ['is']
    };

    function PluralizationController(controller) {
        this.controller = controller;
        return this.getPluralizationRule();
    }

    PluralizationController.prototype.getPluralizationRule = function() {
        var locales,
            locale,
            family,
            index;

        for (family in localeMapping) {
            locales = localeMapping[family];
            index = locales.length;

            while (index--) {
                if (this.controller.ENV.locale === locales[index]) {
                    locale = family;
                    break;
                }
            }

            if (locale !== void 0) {
                break;
            }
        }

        this.pluralizationRule = pluralizationRules[locale] || pluralizationRules.german;
        return this;
    };

    PluralizationController.prototype.pluralize = function(attributes) {
        var numeric = [],
            removeCharacters = /\,/g,
            expression,
            variations;

        if (attributes.phrase.indexOf(attributes.delimiter) < 0) {
            return attributes.phrase;
        }

        // find number argument to use for pluralization
        for (expression in attributes.expressions) {
            expression = +attributes.expressions[expression].toString().replace(removeCharacters, '');
            if (isNaN(expression)) {
                continue;
            }
            numeric.push(expression);
        }

        if (!numeric.length) {
            return attributes.phrase;
        }

        // 1. retrieve pluralization rule
        // 2. separate plural variations from phrase
        // 3. default to first variation if phrase is missing rule
        // 4. return correct phrase variation
        numeric = this.pluralizationRule(numeric[0]);
        variations = attributes.phrase.split(attributes.delimiter);
        numeric = numeric > variations.length - 1 ? 0 : numeric;
        return variations[numeric].replace(REGEXP.trim, '');
    };

    return PluralizationController;

})();

var ENV = (function() {
    'use strict';

    return {
        "api": null,                            // string
        "async": true,                          // boolean
        "bare": true,                           // boolean
        "defaultText": 'Missing translation: ', // string
        "delimiter": '||',                      // string
        "expressionStart": '%{',                // string
        "expressionEnd": '}',                   // string
        "filterDivider": '|',                   // string
        "locale": 'en-us',                      // string
        "nestedTranslationStart": 't(',         // string
        "nestedTranslationEnd": ')',            // string
        "nestedPluralStart": 'p(',              // string
        "nestedPluralEnd": ')',                 // string
        "node": 'span',                         // string
        "notation": '.',                        // string
        "storageKey": 't'                       // string
    };

})();

var VENDORS = (function() {
    'use strict';

    return {
        "backbone": root.Backbone !== void 0,
        "jquery": root.jQuery !== void 0,
        "requirejs": root.require !== void 0
    };

})();

var core = (function() {
    'use strict';

    function clone(obj) {
        var copycat = {};

        for (var prop in obj) {
            copycat[prop] = obj[prop];
        }
        return copycat;
    }

    function escapeRegExp(str) {
        return str.replace(REGEXP.escape, "\\$&");
    }

    function constructJSON(context, prefix, value) {
        if (!prefix.length) {
            return value;
        }
        context[prefix[0]] = constructJSON(context[prefix[0]] || {}, prefix.slice(1), value);
        return context;
    }

    function nestExpressions(expressions, hash) {
        var index = expressions.length,
            response = {},
            itemIndex,
            items,
            item;

        while (index--) {

            item = expressions[index];

            if (/\{(.*?)\}/.test(item)) {

                items = item.slice(1, item.length - 1).split(',');
                itemIndex = items.length;

                while (itemIndex--) {

                    item = items[itemIndex].split(':');

                    if (hash[item[1]] !== void 0) {
                        response[item[0]] = hash[item[1]];
                    }
                }

            } else {

                if (hash[item] !== void 0) {
                    response[item] = hash[item];
                }
            }
        }

        return response;
    }

    function Translator() {
        return this.initialize();
    }

    var prototypes = {

        "applyFilters": function(filters) {

            if (!!filters && filters.sort instanceof Function) {

                // create new filter
                if (filters[1] instanceof Function) {
                    this.filter.set(filters[0], filters[1]);

                } else {
                    this.filter.enqueue(filters);
                }

                return this;
            }

            // trigger filter queue
            this.phrase = this.filter.invoke(this.phrase, filters || '', this.phrases);
            return this;
        },

        "extend": function(phrases, prefix) {
            var item,
                phrase;

            for (item in phrases) {

                phrase = phrases[item];

                if (prefix !== void 0) {
                    item = prefix + this.get('notation') + item;
                }

                // transform object set to string literal
                if (phrase instanceof Object) {
                    this.extend(phrase, item);
                    continue;
                }

                this.phrases[item] = phrase;
            }
        },

        "get": function(setting) {
            return this.ENV[setting];
        },

        "initialize": function() {
            this.phrase = null;
            this.phrases = {};
            this.ENV = clone(ENV);
            this.filter = new Filter(this);
            this.pluralization = new Pluralization(this);
            return this;
        },

        "interpolate": function(hash) {
            var expression,
                regExp;

            if (hash === void 0) {
                return this;
            }

            // search and replace expressions
            for (expression in hash) {
                regExp = new RegExp(escapeRegExp(this.get('expressionStart')) + expression + escapeRegExp(this.get('expressionEnd')), 'g');
                this.phrase = this.phrase.replace(regExp, hash[expression]);
            }

            return this;
        },

        "applyEscaping": function() {
            this.phrase = this.phrase.replace(/\\(\\)?/g, '$1');

            return this;
        },

        "onLocaleChange": function() {
            this.pluralization.getPluralizationRule();
        },

        "parseFilters": function(path) {
            var regExp = new RegExp(escapeRegExp(this.get('filterDivider')), 'g');

            // remove filters from path and add to filter queue
            if (regExp.test(path)) {
                path = path.replace(REGEXP.whitespace, '').split(this.get('filterDivider'));
                this.applyFilters(path.slice(1));
                return path[0];
            }
            return path;
        },

        "pluralize": function(hash) {
            if (hash === void 0) {
                return this;
            }

            this.phrase = this.pluralization.pluralize({
                "phrase": this.phrase,
                "delimiter": this.get('delimiter'),
                "expressions": hash
            });

            return this;
        },

        "templates": function(parentNode) {
            var attribute = 'data-' + this.get('storageKey'),
                bareOriginal = this.get('bare'),
                selector = '[' + attribute + ']',
                attr,
                el,
                expressions,
                expressionArguments,
                expressionLength,
                path,
                paths,
                pathLength,
                translations;

            // grab translation nodes from parent element if defined
            if (parentNode) {
                if (parentNode.constructor === String) {
                    parentNode = VENDORS.jquery ? $(parentNode) : document.querySelectorAll(parentNode);
                }
                translations = VENDORS.jquery && parentNode instanceof jQuery ? parentNode.find(selector) : parentNode.querySelectorAll(selector);

            } else {
                translations = VENDORS.jquery ? $(selector) : document.querySelectorAll(selector);
            }

            // set bare ENV setting to avoid unnecessary DOM nodes.
            this.set('bare', true);

            for (var index = 0, translationLength = translations.length; index < translationLength; index++) {
                el = translations[index];
                expressions = {};
                expressionArguments = '';
                paths = (el.getAttribute(attribute) || el.innerHTML).replace(REGEXP.whitespace, '').split(';');
                pathLength = paths.length;

                while (pathLength--) {
                    if (!(path = paths[pathLength])) {
                        continue;
                    }

                    // cache and remove expression arguments
                    if (REGEXP.argument.test(path)) {
                        expressionArguments = path.match(REGEXP.argument)[0];
                        path = path.replace(expressionArguments, '');
                        expressionArguments = expressionArguments.substr(1, expressionArguments.length - 2).split(',');
                        expressionLength = expressionArguments.length;

                        // build expressions object for translation interpolation
                        while (expressionLength--) {
                            expressions[expressionArguments[expressionLength]] = el.getAttribute(attribute + '-' + expressionArguments[expressionLength]);
                        }

                        expressionArguments = '(' + expressionArguments.join(',') + ')';
                    }

                    // change node content, reset original translation path, and continue iteration if attribute identifiers are null
                    REGEXP.attribute.lastIndex = 0; // reset index for PhantomJS
                    if (!REGEXP.attribute.test(path)) {
                        el.innerHTML = this.translate((paths[pathLength] = path), expressions);
                        paths[pathLength] += expressionArguments;
                        continue;
                    }

                    // create translated node attribute and reset original translation path
                    attr = path.match(REGEXP.attribute)[0];
                    el.setAttribute(attr.substr(1, attr.length - 2), this.translate(path.replace(attr, ''), expressions));
                    paths[pathLength] = path + expressionArguments;
                }
                // reset original translation attribute with minified version
                el.setAttribute(attribute, paths.join(';'));
            }

            // reset bare ENV setting to cached value
            this.set('bare', bareOriginal);

            return this;
        },

        "set": function(setting, value, persistOriginal) {
            var configurationSettings = {};

            // check to see if string arguments were passed and apply to config settings object
            if (setting instanceof Object) {
                configurationSettings = setting;
                persistOriginal = value;
            } else {
                configurationSettings[setting] = value;
            }

            // loop over options object argument and apply pairs to ENV instance
            for (var item in configurationSettings) {
                if (persistOriginal && this.ENV[item] !== null && this.ENV[item] !== void 0) {
                    continue;
                }

                this.ENV[item] = configurationSettings[item];

                if (item === 'locale') {
                    this.onLocaleChange();
                }
            }

            return this;
        },

        "setPhrase": function(path, defaultText) {
            path = this.parseFilters(path);
            this.phrase = this.phrases[path] || defaultText || this.get('defaultText') + path;
            return this;
        },

        "toJSON": function() {
            var response = {},
                paths;

            for (var item in this.phrases) {
                paths = item.split(this.get('notation'));
                response = constructJSON(response, paths, this.phrases[item]);
            }

            return response;
        },

        "translate": function(path, defaultText, hash) {
            var el, response;

            // arguments assignment if object literal was used
            if (path instanceof Object) {
                defaultText = path.defaultText;
                hash = path.expressions;

                if (path.filters instanceof Array) {
                    this.applyFilters(path.filters);
                }

                path = path.path;

            } else {
                // reassign hash argument if deafultText wasn't uesd
                if (defaultText instanceof Object) {
                    hash = defaultText;
                    defaultText = void 0;
                }
            }

            // 1. populate phrase with translation/path lookup
            // 2. replace nested pluralizations with proper phrase
            // 3. choose pluralization syntax for phrase
            // 4. replace nested translations with proper phrases
            // 5. perform replacement expression handleing
            // 6. apply listed filters to translation phrase
            this.setPhrase(path, defaultText)
                .unnestPluralizations(hash)
                .pluralize(hash)
                .unnestTranslations(hash)
                .interpolate(hash)
                .applyEscaping()
                .applyFilters(path);

            // DOM wrapper for transaltion if ENV.bare flag is falsy
            if (!this.get('bare')) {
                el = document.createElement(this.get('node'));
                el.setAttribute('data-' + this.get('storageKey'), path);
                el.innerHTML = this.phrase;
            }

            response = el || this.phrase;
            this.phrase = null;

            return response;
        },

        "unnestPluralizations": function(hash) {

            if (this.phrase.indexOf(this.get('nestedPluralStart')) < 0) {
                return this;
            }

            var regExp = new RegExp(escapeRegExp(this.get('nestedPluralStart')) + '(.*?)' + escapeRegExp(this.get('nestedPluralEnd')), 'g'),
                phrase = this.phrase,
                nestedPlurals = phrase.match(regExp) || [],
                index = nestedPlurals.length,
                pluralization,
                pluralizationArguments,
                expressions = {};

            // search and replace of nested pluralizations
            while (index--) {
                pluralization = nestedPlurals[index];
                pluralizationArguments = pluralization.slice(this.get('nestedPluralStart').length, pluralization.length - this.get('nestedPluralEnd').length).replace(REGEXP.whitespace, '').split(',');
                pluralizationArguments[1] = pluralizationArguments[1] || '';
                expressions[pluralizationArguments[1]] = hash[pluralizationArguments[1]];

                pluralizationArguments = this.pluralization.pluralize({
                    "phrase": pluralizationArguments[0],
                    "delimiter": this.get('delimiter'),
                    "expressions": expressions
                });

                phrase = phrase.replace(pluralization, pluralizationArguments);
            }

            this.phrase = phrase;
            return this;
        },

        "unnestTranslations": function(hash) {

            if (this.phrase.indexOf(this.get('nestedTranslationStart')) < 0) {
                return this;
            }

            var regEx = new RegExp(escapeRegExp(this.get('nestedTranslationStart')) + '(.*?)' + escapeRegExp(this.get('nestedTranslationEnd')), 'g'),
                phrase = this.phrase,
                nestedTranslations = phrase.match(regEx) || [],
                index = nestedTranslations.length,
                nestedTranslation,
                expression,
                expressionArguments;

            // search and replace of nested transaltions
            while (index--) {
                expression = nestedTranslations[index];
                expressionArguments = expression.slice(this.get('nestedTranslationStart').length, expression.length - this.get('nestedTranslationEnd').length).replace(REGEXP.whitespace, '');

                if (/\{(.*?)\}/.test(expressionArguments)) {
                    expressionArguments = expressionArguments.split(/,(.+)?/);
                } else {
                    expressionArguments = expressionArguments.split(',');
                }

                nestedTranslation = this.translate(expressionArguments[0], nestExpressions(expressionArguments.slice(1), hash));
                phrase = phrase.replace(expression, nestedTranslation);
            }

            this.phrase = phrase;
            return this;
        }
    };

    for (var method in prototypes) {
        Translator.prototype[method] = prototypes[method];
    }

    return new Translator();

})();

var COUNTRY_CODES = (function() {
    'use strict';

    return {
        "US": 'en-us',
        "UK": 'en-gb'
    };

})();

var api = (function() {
    'use strict';

    var noConflict, method, methods;

    noConflict = {
        "translate": root.translate,
        "t": root.t
    };

    function translator() {
        return core.translate.apply(core, arguments);
    }

    methods = {

        "extend": function() {
            var phrases = Array.prototype.slice.call(arguments).reverse(),
                phrasesLength = phrases.length;

            while (phrasesLength--) {
                core.extend(phrases[phrasesLength]);
            }

            return this;
        },

        "filter": function() {
            if (arguments.length) {
                core.applyFilters(Array.prototype.slice.call(arguments));
            }
            return this;
        },

        "get": function(setting) {
            return core.get(setting);
        },

        "getCountryCode": function(code) {
            return COUNTRY_CODES[code];
        },

        "isCountryCode": function(code) {
            return this.get('locale') === this.getCountryCode(code);
        },

        "noConflict": function() {
            root.translate = noConflict.translate;
            root.t = noConflict.t;
            return this;
        },

        "render": function(parent) {
            core.templates(parent);
            return this;
        },

        "replace": function() {
            core.phrases = {};
            return this.extend.apply(this, arguments);
        },

        "set": function() {
            core.set.apply(core, arguments);
            return this;
        },

        "toJSON": function(exports) {
            return core.toJSON(exports);
        }
    };

    for (method in methods) {
        translator[method] = methods[method];
    }

    return translator;

})();

// extend Backbone.Events if backbone.js is available
if (VENDORS.backbone) {
    _.extend(api, Backbone.Events);
}

// define module instance if require.js is available
if (VENDORS.requirejs) {
    define('ti18n', function() {
        return api;
    });
}

root.t = root.translate = api;

})(this);
